import {useState, useEffect, useContext} from 'react'
import { useFirestoreGeneralOrderBy } from "../../firebase/useFirestore";
import { Responses } from "../../StateManagment/Responses";

const PersonaFieldType = ({field, reportResponses}) => {
    // Context
    const [responses, setResponses] = useContext(Responses)

    // State
    const [range, setRange] = useState(0)

    // Firestore
    const multipleOptions = useFirestoreGeneralOrderBy('MultipleQuestionOptions', 'Field', field.ID, 'Position', 'asc')
    const matrixRows = useFirestoreGeneralOrderBy('MatrixQuestionRows', 'Field', field.ID, 'Position', 'asc')
    const matrixColumns = useFirestoreGeneralOrderBy('MatrixQuestionColumns', 'Field', field.ID, 'Position', 'asc')

    const start = Number(field.ReachStart)
    const end = Number(field.ReachEnd)

    console.log(reportResponses)

    // Set the range for the scale
    useEffect(() => {
        if(field.Type === 'scale'){
            const range = (start, end) => {
                return Array(end - start + 1).fill().map((_, idx) => start + idx)
            }

            const result = range(start, end)
            setRange(result)
        }
    }, [field, start, end])

    // Get the prefilled value
    const getPrefilledValue = (fieldID, itemID, optionType = null) => {
        const existingResponse = reportResponses.find(response => response.FieldID === fieldID && (!optionType || response.OptionType === optionType) && (!itemID || response.Itemid === itemID))
        return existingResponse ? existingResponse.Input : null;
    }

    // Update the responses
    const updateResponses = (fieldID, input, optionType, itemid) => {
        const previousResponses = responses.filter(response => response.FieldID === fieldID)

        if(previousResponses.length > 0){
            const newResponses = responses.filter(response => response.FieldID !== fieldID)
            setResponses(newResponses)
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: optionType, Itemid: itemid }])
        } else {
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: optionType, Itemid: itemid}]);
        }
    }

    // Handle deselecting multiple matrix
    const handleDeselectMultiMatrix = (isSelected, fieldID, input, optionType, inputType, itemid) => {
        if(isSelected){
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: optionType, Itemid: itemid}])
        } else {
            const filteredResponses = responses.filter(item => !(item.Itemid === itemid && item.Input === input));
            setResponses(filteredResponses)
        }
    }

    // Handle deselecting multiple multiple
    const handleDeselectMultiMulti = (isSelected, fieldID, input, optionType, inputType, itemid) => {
        if(isSelected){
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: optionType, Itemid: itemid}])
        } else {
            const filteredResponses = responses.filter(item => item.Itemid !== itemid );
            setResponses(filteredResponses)
        }
    }

    // Handle input
    const inputHandler = (e) => {
        const input = e.target.value
        const fieldID = e.target.dataset.id

        if(field.Type === 'paragraph'){
            updateResponses(fieldID, input)
        } else if(field.Type === 'scale'){
            const numberInput = parseInt(input)
            updateResponses(fieldID, numberInput)
        } else if(field.Type === 'multiple-multiple'){ 
            const optionType = e.target.dataset.optiontype 
            const inputType = e.target.dataset.inputtype
            const itemid = e.target.dataset.itemid

            if(inputType === 'otherInput'){
                updateResponses(fieldID, input, optionType, itemid)
            } else {
                const isSelected = e.target.checked
                handleDeselectMultiMulti(isSelected, fieldID, input, optionType, inputType, itemid)
            }
        } else if(field.Type === 'multiple-one'){
            const optionType = e.target.dataset.optiontype
            const itemid = e.target.dataset.itemid

            updateResponses(fieldID, input, optionType, itemid)
        } else if(field.Type === 'matrix-multiple'){
            const isSelected = e.target.checked
            const itemid = e.target.dataset.itemid
            const optionType = e.target.dataset.optiontype
            const inputType = e.target.dataset.inputtype
            handleDeselectMultiMatrix(isSelected, fieldID, input, optionType, inputType, itemid)
        }
    }

    // Return the field type
    if(field.Type === 'paragraph'){
        return (
            <textarea 
            cols="30" 
            rows="10" 
            placeholder='Geef hier uw antwoord'
            data-id={field.ID} 
            onChange={inputHandler}
            defaultValue={getPrefilledValue(field.ID)}>
            </textarea>
        )
    } else if(field.Type === 'scale'){
        return (
            <div id='scale-container'>
                {field.ReachStartLable}
                {range && range.map(btn => (
                    <div key={field.ID}id='question-type-label-container'>
                        <input 
                        type="radio"
                        name={field.ID} 
                        value={btn} 
                        data-id={field.ID}
                        onChange={inputHandler}
                        defaultChecked={getPrefilledValue(field.ID) === btn}/>
                        <label htmlFor={btn}>{btn}</label>
                    </div>
                ))}
                {field.ReachEndLabel}
            </div>
        )
    } else if(field.Type === 'multiple-multiple'){
        return(
            <div id='options-container'>
                {multipleOptions && multipleOptions.map(item => (
                    <span key={item.ID}>
                       <input
                        className='question-checkbox-input'
                        type="checkbox"
                        id={item.ID}
                        name='options'
                        data-itemid={item.ID}
                        data-optiontype={item.Option === 'other' ? 'other' : 'normal'}
                        data-inputtype={'checkboxInput'}
                        data-id={field.ID}
                        value={item.Option}
                        onChange={inputHandler}
                        defaultChecked={getPrefilledValue(field.ID, item.ID) !== null}
                        />
                        <label htmlFor={item.Option}>{item.Option === 'other' ? 'Anders...' : item.Option}</label>
                        <input 
                        type="text" 
                        data-id={field.ID} 
                        data-itemid={item.ID}
                        data-optiontype={item.Option === 'other' ? 'other' : 'normal'}
                        data-inputtype={'otherInput'}
                        style={{display: item.Option === 'other' ? 'block' : 'none'}} 
                        placeholder='Vul hier je antwoord in' 
                        onChange={inputHandler}
                        defaultValue={getPrefilledValue(field.ID, item.ID, 'other')}/>
                    </span> 
                ))}
            </div>
        )
    } else if(field.Type === 'multiple-one'){
        return(
            <div id='options-container'>
                {multipleOptions && multipleOptions.map(item => (
                    <span  key={item.ID}>
                        <input 
                        className='question-checkbox-input' 
                        type="radio" 
                        id={item.ID} 
                        name='option'
                        data-itemid={item.ID}
                        data-optiontype={item.Option === 'other' ? 'other' : 'normal'}
                        data-id={field.ID} 
                        value={item.Option} 
                        data-type={item.Option}
                        onChange={inputHandler}
                        defaultChecked={getPrefilledValue(field.ID, item.ID) === item.Option}/>
                        <label htmlFor={item.Option}>{item.Option === 'other' ? 'Anders, namelijk..' : item.Option}</label>
                        <input 
                        type="text" 
                        data-id={field.ID} 
                        data-itemid={item.ID}
                        data-optiontype={item.Option === 'other' ? 'other' : 'normal'}
                        style={{display: item.Option === 'other' ? 'block' : 'none'}} 
                        placeholder='Vul hier je antwoord in' 
                        onChange={inputHandler}
                        defaultValue={getPrefilledValue(field.ID, item.ID, 'other')}/>
                    </span> 
                ))}
            </div>
        )
    } else if(field.Type === 'matrix-multiple'){
        return(
            <div id='options-container'>
                <div className='matrix-column-title-container'>
                    {matrixColumns && matrixColumns.map(column => (
                        <div key={column.ID}>
                            <p>{column.Title}</p>
                        </div>
                    ))}
                </div>
                {matrixRows && matrixRows.map(row => (
                    <div key={row.ID} id='matrix-row-container'>
                        <p>{row.Title}</p>
                        {matrixColumns && matrixColumns.map(column => (
                            <div key={column.ID} id='matrix-column-container'>
                                <input 
                                className='question-checkbox-input' 
                                type="checkbox" 
                                id={column.ID} 
                                data-id={field.ID}
                                data-itemid={column.ID}
                                name={column.Title} 
                                value={`${column.Title}/${row.Title}`}
                                onChange={inputHandler}
                                defaultChecked={getPrefilledValue(field.ID, column.ID) !== null}/>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        )
    } else {
        return null
    }
}

export default PersonaFieldType