import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useHistory } from 'react-router-dom';
import { client } from '../../hooks/Client';
import useSettings from '../../hooks/Settings';

const MixedBarChart = ({data, customTooltip}) => {

  // Hooks
  const history = useHistory()
  const primairyColor = useSettings().PrimaryColor
  const secundairyColor = useSettings().SecundairyColor

  return (
    <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={500}
          height={300}
          data={data || []}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={customTooltip} />
          <Legend />
          <Bar dataKey="Aanwezigheid" stackId="a" fill={'#47acc3'} onClick={(data) => history.push(`/${client}/personapresencereportdetail/${data.id}`)}/>
          <Bar dataKey="Geroosterd" stackId="a" fill={'#206c7f'}  onClick={(data) => history.push(`/${client}/personapresencereportdetail/${data.id}`)}/>
        </BarChart>
      </ResponsiveContainer>
  )
}

export default MixedBarChart