import HomeMultipleBarChart from "../Visualisations/HomeMultipleBarChart"
import ScaleMomentVisualisation from "../Visualisations/ScaleMomentVisualisation"
import MultipleMultilineGraphResponsive from "../Visualisations/MultipleMultilineGraphResponsive"
import DevelopmentMultilineGraphResponsive from "../Visualisations/DevelopmentMultilineGraphResponsive"
import ScaleDevelopmentResultsGraphResponsive from "../Visualisations/ScaleDevelopmentResultsGraphResponsive"
import ParagraphBarChartResponsive from "../Visualisations/ParagraphBarChartResponsive"
import OutputResultsGraphResponsive from "../Visualisations/OutputResultsGraphResponsive"
import OpenQuestionSlider from "../liveReport/OpenQuestionSlider"
import PieChart from "../Visualisations/PieChart"
import ParagraphMomentData from "../graphData/ParagraphMomentData"
import MultiMomentData from "../graphData/MultiMomentData"
import MatrixOneData from "../graphData/MatrixOneData"
import MatrixDevelopmentData from "../graphData/MatrixDevelopmentData"

const ChartType = ({ type, momentId, fieldId, indicator, startDate, endDate, researchId, graphType }) => {
    // Determine the correct field value
    const field = indicator?.ID || fieldId;
  
    // Function to render chart type
    const renderChart = () => {
      switch (type) {
        case 'multiple-one-moment':
        case 'multiple-multiple-moment':
          return <MultiMomentData moment={momentId} field={field} startDate={startDate} endDate={endDate} graphType={graphType} />;
          
        case 'scale-moment':
          return <ScaleMomentVisualisation moment={momentId} field={field} startDate={startDate} endDate={endDate} />;
          
        case 'paragraph-moment':
          return <> <ParagraphMomentData moment={momentId} field={field} startDate={startDate} endDate={endDate} graphType={graphType} />
                    <OpenQuestionSlider moment={momentId} field={field} startDate={startDate} endDate={endDate} />
                  </>

        case 'matrix-one-moment':
          return <MatrixOneData field={field} startDate={startDate} endDate={endDate} />; 

        case 'matrix-one-development':
          return <MatrixDevelopmentData field={field} startDate={startDate} endDate={endDate} researchID={researchId} />; 

        case 'multiple-one-development':
        case 'multiple-multiple-development':
          return <MultipleMultilineGraphResponsive field={field} researchID={researchId} startDate={startDate} endDate={endDate} />;
          
        case 'scale-development':
          return <ScaleDevelopmentResultsGraphResponsive field={field} researchID={researchId} startDate={startDate} endDate={endDate} />;
          
        case 'paragraph-development':
          return <DevelopmentMultilineGraphResponsive field={field} researchID={researchId} startDate={startDate} endDate={endDate} />;
          
        case 'output':
          return <OutputResultsGraphResponsive output={field} color={indicator?.Color} startDate={startDate} endDate={endDate} />;
          
        default:
          return <p>Unknown type</p>;
      }
    };
  
    return <>{renderChart()}</>;
  };
  
  export default ChartType;