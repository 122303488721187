import { useFirestoreID, useFirestore } from "../../firebase/useFirestore";
import { useState,  useContext, useEffect } from "react";
import { db } from "../../firebase/config.js"
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ScrollToTop from "../../hooks/ScrollToTop";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import firebase from 'firebase'
import { bucket } from '../../firebase/config';
import useSettings from "../../hooks/Settings";
import editIcon from '../../images/icons/edit-icon.png'
import { Auth } from "../../StateManagment/Auth";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { PersonaBuilderSystemMessage } from "../../hooks/impactAI/Prompts"
import { useHistory } from "react-router-dom";
import Data from "./Data";
import Goals from "./Goals";
import Research from "./Research";
import Reports from "./Reports";
import Dashboard from "./Dashboard";
import Presence from "./Presence";
import { PersonaNavigation } from "../../StateManagment/PersonaNavigation";

const PersonaBuilder = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [personaNavigation, setPersonaNavigation] = useContext(PersonaNavigation)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen]
    } = useContext(ImpactAI);

    // State
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')
    const [showEditPhoto, setShowEditPhoto] = useState(false)
    const [personaDocid, setPersonaDocid] = useState('123')
    const [title, setTitle] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    // Hooks
    const route = Location()[3]
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const personas = useFirestoreID('Personas', route)

    // ImpactAI
    const pageSystemMessage = PersonaBuilderSystemMessage()
    // const pageToolCallContent = LiveReportBuilderToolCallContent()
    // const pageSaveMessage = LiveReportBuilderSaveMessage()
    const pageStartMessage = `Hoi ${auth?.ForName}. Hier kun je de instellingen van persona '${title}' wijzigen.
    Kan ik je ergens mee helpen?`
    // const pageCollection = 'QuestionnaireFields'
    // const pageField = 'Question'
    // const pageParentId = ''
    const pageType = 'personabuilder'


    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        // setToolCallContent(pageToolCallContent)
        // setSaveMessage(pageSaveMessage)
        // setCollection(pageCollection)
        // setField(pageField)
        // setParentId(ID)
        // setExampleMessages([])
        // setDocid(pageDocid)
    }, [auth, title])

    // Set persona meta
    useEffect(() => {
        personas && personas.map(item => (
            setPersonaDocid(item.docid),
            setTitle(item.Name),
            setStartDate(item.StartDate),
            setEndDate(item.EndDate)
        ))
    }, [personas])

    const nameHandler = (e) => {

        const docid = e.target.dataset.docid
        const value = e.target.value

        db.collection('Personas')
        .doc(docid)
        .update({
            Name: value
        })
        .then(() => {
            setSaved('flex')
         })

    }

    const photoHandler = (e) => {

        const photo = e.target.files[0]
        const docid = e.target.dataset.docid

        const storageRef = bucket.ref("/ProfilePhotos/" + photo.name);
        const uploadTask = storageRef.put(photo)

        uploadTask.then(() => {
          
            uploadTask.on('state_changed', snapshot => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
                console.log('Upload is paused');
                break;
            case firebase.storage.TaskState.RUNNING:
                console.log('Upload is running');
                break;
            }
            }, (err) => {
                alert(err)
            }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log('File available at', downloadURL);

            db.collection('Personas')
            .doc(docid)
            .update({
                Photo: downloadURL
            })
            .then(() => {
                setSaved('flex')
                setShowEditPhoto(false)
             })

                })
            })
        })
    }  

    const personaStatus = (status) => {

        if (status === 'active') {
            return 'Actief'
        } else if (status === 'inactive') {
            return 'Inactief'
        } else if (status === 'pre-registration') {
            return 'Vooraanmelding'
        } else if (status === 'outflow') {
            return 'Uitgestroomd'
        }
    }

    const statusColor = (status) => {

        if (status === 'active') {
            return 'green'
        } else if (status === 'inactive') {
            return 'red'
        } else if (status === 'pre-registration') {
            return 'orange'
        } else if (status === 'outflow') {
            return 'grey'
        }
    }

  return (
    <div className="main">
      <div className="main-container" >
        <div className='page-header'>
            <PersonOutlineOutlinedIcon/>
            <h1>Deelnemer instellingen</h1>
        </div>
            <div id='live-report-builder-content-container'>
                {personas && personas.map(item => (
                    <div key={item.ID} id='persona-personal-data-container'>
                        <div id='edit-persona-phor-container'>
                            <img className='persona-image' src={item.Photo} alt="" />
                            <img id='edit-persona-photo-icon' src={editIcon} alt="" onClick={() => setShowEditPhoto(!showEditPhoto)}/>
                        </div>
                        <input type="file" data-docid={item.docid} onChange={photoHandler} style={{display: showEditPhoto ? 'block' : 'none'}}/>
                        <input id='edit-persona-name-input' type="text" defaultValue={item.Name} data-docid={item.docid} onChange={nameHandler}/>
                        <div>
                            <p style={{color: statusColor(item.Status)}}>{personaStatus(item.Status)}</p>
                        </div>
                    </div>
                ))}
                <div className="select-activity-container select-activity-container-personas">
                    <div className="select-activity-inner-container">
                        <div className='activity-select-item-container' onClick={() => setPersonaNavigation('dashboard')} style={{backgroundColor: personaNavigation === 'dashboard' ? secundairyColor : '#83edff14'}}>
                            <p onClick={() => setPersonaNavigation('dashboard')} style={{color: personaNavigation === 'dashboard' ? 'white' : '#616161'}}>Dashboard</p>
                        </div>
                        <div className='activity-select-item-container' onClick={() => setPersonaNavigation('data')} style={{backgroundColor: personaNavigation === 'data' ? secundairyColor : '#83edff14'}}>
                            <p onClick={() => setPersonaNavigation('data')} style={{color: personaNavigation === 'data' ? 'white' : '#616161'}}>Gegevens</p>
                        </div>
                        <div className='activity-select-item-container' onClick={() => setPersonaNavigation('presence')} style={{backgroundColor: personaNavigation === 'presence' ? secundairyColor : '#83edff14'}}>
                            <p onClick={() => setPersonaNavigation('presence')} style={{color: personaNavigation === 'presence' ? 'white' : '#616161'}}>Aanwezigheid</p>
                        </div>
                        <div className='activity-select-item-container' onClick={() => setPersonaNavigation('goals')} style={{backgroundColor: personaNavigation === 'goals' ? secundairyColor : '#83edff14'}}>
                            <p onClick={() => setPersonaNavigation('goals')} style={{color: personaNavigation === 'goals' ? 'white' : '#616161'}}>Doelen</p>
                        </div>
                        <div className='activity-select-item-container' onClick={() => setPersonaNavigation('report')} style={{backgroundColor: personaNavigation === 'report' ? secundairyColor : '#83edff14'}}>
                            <p onClick={() => setPersonaNavigation('report')} style={{color: personaNavigation === 'report' ? 'white' : '#616161'}}>Rapportages</p>
                        </div>
                        <div className='activity-select-item-container' onClick={() => setPersonaNavigation('research')} style={{backgroundColor: personaNavigation === 'research' ? secundairyColor : '#83edff14'}}>
                            <p onClick={() => setPersonaNavigation('research')} style={{color: personaNavigation === 'research' ? 'white' : '#616161'}}>Onderzoeken</p>
                        </div>
                        <div className='activity-select-item-container' onClick={() => setPersonaNavigation('live-report')} style={{backgroundColor: personaNavigation === 'live-report' ? secundairyColor : '#83edff14'}}>
                            <p onClick={() => setPersonaNavigation('live-report')} style={{color: personaNavigation === 'live-report' ? 'white' : '#616161'}}>Live-rapporten</p>
                        </div>
                    </div>
                </div>
                {personas && personas.map(item => (
                    <div key={item.ID}>
                        <Data personaNavigation={personaNavigation} item={item} personaDocid={personaDocid}/>
                        <Presence personaNavigation={personaNavigation} item={item}/>
                        <Goals personaNavigation={personaNavigation}/>
                        <Research personaNavigation={personaNavigation} item={item} route={route} personaDocid={personaDocid}/>
                        <Reports personaNavigation={personaNavigation} item={item} route={route} personaDocid={personaDocid}/>
                        <Dashboard personaNavigation={personaNavigation} item={item} courseStartDate={startDate} courseEndDate={endDate}/>
                    </div>
                ))}
            </div>

        </div>
    </div>
  )
}

export default PersonaBuilder