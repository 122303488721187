import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import Location from "../../hooks/Location"
import { useFirestoreID, useFirestoreGeneralThree } from '../../firebase/useFirestore';
import useSettings from "../../hooks/Settings";
import EffectMeta from '../../components/effects/EffectMeta';
import { useState } from 'react';
import { client } from '../../hooks/Client';
import { db } from "../../firebase/config";
import { useHistory } from "react-router-dom";

const AddIndicatorsToQuestionaire = () => {

    // State
    const [checkedIndicators, setCheckedIndicators] = useState([])

    // Hooks
    const questionaireId = Location()[3]
    const researchId = Location()[4]
    const secundairyColor = useSettings().SecundairyColor
    const history = useHistory()

    // Firestore
    const indicators = useFirestoreGeneralThree('QuestionnaireFields', 'Indicator', 'true', 'QuestionnaireID', '', 'Standard', 'false')
    const questionaireField = useFirestoreID('QuestionnaireFields', questionaireId ? questionaireId : '')

    // Update the selected question
    const handleCheckboxChange = (id, docid) => {
        setCheckedIndicators(prevState => {
            const index = prevState.findIndex(item => item.id === id);
            if (index > -1) {
                // The question is currently checked, remove it from the array
                return prevState.filter((_, i) => i !== index);
            } else {
                // The question is not checked, add it to the array
                return [...prevState, {id, docid}];
            }
        });
    };

    // Add questionaire field
    const updateQuestionaireField = async (item, index) => {

        await db.collection('QuestionnaireFields')
        .doc(item.docid)
        .update({
            QuestionnaireID: questionaireId,
            Position: questionaireField.length + 1 + index,
        })
    }

    // Save indicators to questionaire
    const updateIndicators = (e) => {

        checkedIndicators.map(async (item, index) => {

            // For each checked indicator, update the questionaire field
            await updateQuestionaireField(item, index)
                
        })

        history.push(`/${client}/questionairebuilder/${questionaireId}/${researchId}`)


    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <HighlightAltOutlinedIcon/>
                <h1>Indicatoren toevoegen aan vragenlijst</h1>
            </div>
            <div className="table-container dashboard-container">
                <h2>Indicatoren</h2>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>SELECTEER</th>
                        <th style={{backgroundColor: secundairyColor}}>VRAAG</th>
                        <th style={{backgroundColor: secundairyColor}}>EFFECT</th>
                    </tr>
                        {indicators && indicators.map((indicator, index) => (
                            <>
                            {indicator.EffectId && indicator.EffectId.length > 0 ?
                             <tr>
                                <td>
                                    <input 
                                    type="checkbox" 
                                    checked={checkedIndicators.some(q => q.id === indicator.ID)}
                                    onChange={() => handleCheckboxChange(indicator.ID, indicator.docid)}
                                    />
                                </td>
                                <td>
                                    <p className='table-p'>{indicator.Question}</p>
                                </td>
                                <td>
                                    {Array.isArray(indicator.EffectId) && indicator.EffectId?.map((item) => (
                                         <EffectMeta item={item}/>
                                    ))}
                                </td>
                            </tr>
                            : null
                            }
                            </>
                        ))}
                </table>
                <div className='button-container'>
                    <button className='button-simple' onClick={updateIndicators}>Opslaan</button>
                </div>
            </div>

            
        </div>
    </div>
  )
}

export default AddIndicatorsToQuestionaire