import { useFirestoreGeneralArrayContains } from '../../firebase/useFirestore'
import PersonaFieldType from './PersonaFieldType'
import { v4 as uuid } from 'uuid'

const EffectIndicator = ({effectId, reportResponses}) => {

    // Firestore
    const questionnaireField = useFirestoreGeneralArrayContains('QuestionnaireFields', 'EffectId', effectId ? effectId : '')

  return (
    <div>
        {questionnaireField && questionnaireField.map(field => (
            <div key={uuid()}>
                <p>{field.Question}</p>
                <PersonaFieldType 
                field={field}
                reportResponses={reportResponses}
                />
            </div>
        ))}
    </div>
  )
}

export default EffectIndicator