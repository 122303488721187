import PunchClockOutlinedIcon from '@mui/icons-material/PunchClockOutlined';
import Location from "../../hooks/Location"
import { useFirestoreGeneral } from '../../firebase/useFirestore';
import { db } from '../../firebase/config';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useContext } from "react";

const PersonaPresenceReportDetails = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // Hooks
    const reportId = Location()[3]

    // Firestore
    const presenceReports = useFirestoreGeneral('PersonaPresenceReports', 'ID', reportId ? reportId : ''); 

    console.log(presenceReports)

    // Update report type
    const updateReportType = (e) => {
      const value = e.target.value

      if(value === 'absent'){
        db.collection('PersonaPresenceReports')
          .doc(e.target.dataset.docid)
          .update({
            Start: '',
            End: ''
          })
      }

      db.collection('PersonaPresenceReports')
        .doc(e.target.dataset.docid)
        .update({
          Type: value
        })
  
      setSaved(true)
    }

    // Update start and endtime
  const startTimeHandler = (e) => {
    const value = e.target.value

    db.collection('PersonaPresenceReports')
      .doc(e.target.dataset.docid)
      .update({
        Start: value
      })

    setSaved(true)
  }

  const endTimeHandler = (e) => {
    const value = e.target.value

    db.collection('PersonaPresenceReports')
      .doc(e.target.dataset.docid)
      .update({
        End: value
      })

    setSaved(true)
  }

  // Update absent reason
  const absentReasonHandler = (e) => {
    const value = e.target.options[e.target.selectedIndex].value

    db.collection('PersonaPresenceReports')
      .doc(e.target.dataset.docid)
      .update({
        Reason: value
      })

    setSaved(true)
  }

  return (
    <div className="main">
      <div className="main-container">
        <div className="page-header">
          <PunchClockOutlinedIcon />
          <h1>Aanwezigheidsrapport</h1>
        </div>
        <div className='table-container dashboard-container'>
           {presenceReports && presenceReports.map((report) => (
            <div>
                <h2>{report.Date}</h2>
                <div>
                    <select name="" id="" defaultValue={report.Type} data-docid={report.docid} onChange={updateReportType}>
                        <option value="correct">Aanwezig</option>
                        <option value="absent">Afwezig</option>
                        <option value="correction">Aanpassing</option>
                    </select>
                </div>

                <div style={{display: report.Type === 'correct' ? 'block' : 'none'}}>
                  <h2>Start en eindtijd</h2>
                  <div>
                    <p>{report.Start}</p>
                    <p>{report.End}</p>
                  </div>
                </div>

                <div style={{display: report.Type === 'correction' ? 'block' : 'none'}}>
                <h2>Start en eindtijd</h2>
                  <div>
                    <input type="time" defaultValue={report.Start} data-docid={report.docid} onChange={startTimeHandler} />
                    <input type="time" defaultValue={report.End} data-docid={report.docid} onChange={endTimeHandler} />
                  </div>
                </div>

                <div style={{display: report.Type === 'absent' ? 'block' : 'none'}}>
                    <h2>Afwezig</h2>
                    <div>
                      <select name="" id="" defaultValue={report.Reason} data-docid={report.docid} onChange={absentReasonHandler}>
                        <option value="">-- Selecteer reden afwezigheid --</option>
                        <option value="ziek">Ziek</option>
                        <option value="verlof">Verlof</option>
                        <option value="overig">Overig</option>
                      </select>
                    </div>
                </div>
            </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default PersonaPresenceReportDetails