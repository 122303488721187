import { useFirestoreGeneralOrderBy, useFirestoreGeneral } from '../../firebase/useFirestore';
import PersonaGoalData from '../../components/personas/PersonaGoalData';
import MixedBarChart from '../../components/Visualisations/MixedBarChart';
import { useEffect, useState, useContext } from 'react';
import ErasorIcon from '../../images/icons/erasor-icon.png';
import XIcon from '../../images/icons/x-icon.png';
import CalendarIcon from '../../images/icons/calendar-icon.png';
import { db, timestamp } from '../../firebase/config';
import { Auth } from '../../StateManagment/Auth'
import { client } from "../../hooks/Client"
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';

const Dashboard = ({ personaNavigation, item, courseStartDate, courseEndDate }) => {
  // Context
  const [auth] = useContext(Auth);

  // State
  const [totalAbsent, setTotalAbsent] = useState(0);
  const [totalCorrections, setTotalCorrections] = useState(0);
  const [startDate, setStartDate] = useState(courseStartDate);
  const [endDate, setEndDate] = useState(courseEndDate);
  const [presenceData, setPresenceData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for data

  // Hooks
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const history = useHistory()

  // Firestore
  const goals = useFirestoreGeneral('PersonaGoals', 'PersonaID', item.ID ? item.ID : '');
  const presenceReports = useFirestoreGeneralOrderBy('PersonaPresenceReports', 'PersonaID', item.ID ? item.ID : '', 'Timestamp', 'asc'); // Reported presence by support worker
  const schedule = useFirestoreGeneral('PersonaShedule', 'PersonaID', item.ID ? item.ID : ''); // Shedule of the persona


  // Helper function to get the start time from the schedule
  const getStartTimeFromSchedule = (weekday) => {

    console.log(weekday);
    const day = schedule && schedule.filter(item => item.Day === weekday);
    return day[0].StartTime;
  }

  // Helper function to get the end time from the schedule
  const getEndTimeFromSchedule = (weekday) => {
    const day = schedule && schedule.filter(item => item.Day === weekday);
    return day[0].EndTime;
  }

 // Create historical presence reports
const createHistoricalPresenceReports = () => {
  
  const scheduleArray = [];
  const today = new Date();
  const currentWeekday = today.getDay();

  // Assuming `schedule` is defined and is an array of objects
  schedule && schedule.map(item => {
    const weekday = item.Day;

    const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const dayNumber = dayNames.indexOf(weekday);

    scheduleArray.push(dayNumber); // Do not add +1 to maintain 0-based indexing
  });

  // Get last weekday in the scheduleArray before today
  let lastWeekday = scheduleArray.filter(day => day < currentWeekday).pop();

  // If the last weekday is undefined, get the last weekday in the scheduleArray
  if (lastWeekday === undefined) {
    lastWeekday = scheduleArray[scheduleArray.length - 1];
  }

  // Get the last occurrence of the last weekday before today
  const lastWeekdayDate = new Date(today.getTime());
  lastWeekdayDate.setDate(today.getDate() - (currentWeekday - lastWeekday));

  // Function to normalize date by setting time to midnight (00:00:00)
  const normalizeDate = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0); // Set time to midnight
    return newDate;
  };

  // Normalize both dates for comparison
  const normalizedStartDate = normalizeDate(startDate);
  const normalizedLastWeekdayDate = normalizeDate(lastWeekdayDate);

  // Create presence reports for each weekday from course start date to the last weekday before today
  let currentDate = new Date(normalizedStartDate.getTime());
  const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

  while (normalizeDate(currentDate) <= normalizedLastWeekdayDate) {
    const currentWeekday = dayNames[currentDate.getDay()];
    
    if (scheduleArray.includes(currentDate.getDay())) {
      // Create a report for this day 
      db.collection('PersonaPresenceReports').add({
        Date: currentDate.toLocaleDateString('nl-NL', options),
        Type: 'correct',
        Start: getStartTimeFromSchedule(currentWeekday),
        End: getEndTimeFromSchedule(currentWeekday),
        Absent: '',
        Reason: '',
        PresenceID: '',
        PersonaID: item.ID,
        CompagnyID: client,
        Timestamp: timestamp,
        Author: auth.ID,
        ID: uuid()
      });
      console.log(`Presence report created for: ${currentWeekday} (${currentDate.toDateString()})`);
    }
    
    // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  // Set default start and end date
  useEffect(() => {
    setStartDate(courseStartDate);
    setEndDate(courseEndDate);
  }, [courseStartDate, courseEndDate]);

  // Set presence quick overview data
  useEffect(() => {
    const totalPresent = [];
    const totalAbsent = [];
    const totalCorrections = [];

    presenceReports && presenceReports.map(report => {
      if (report.Type === 'correct') {
        totalPresent.push(report);
      } else if (report.Type === 'absent') {
        totalAbsent.push(report);
      } else if (report.Type === 'correction') {
        totalCorrections.push(report);
      }

    });

    setTotalAbsent(totalAbsent.length);
    setTotalCorrections(totalCorrections.length);

  }, [presenceReports]);

    
  // Helper arrays to map days and months
  const formatDateToDutchString = (date) => {

    const daysOfWeek = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const monthsOfYear = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

    // Create a JavaScript Date object
    const dayName = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const monthName = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    // Return formatted date string
    return `${dayName} ${day} ${monthName} ${year}`;
}

  // Helper function to normalize the date object
  const normalizeDate = (reportDate) => {
    // Check if the date is a string like "donderdag 13 juni 2024"
    if (typeof reportDate === 'string') {
      return reportDate; // Already in the desired format
    }

    // Check if the date is in timestamp format
    if (reportDate.seconds !== undefined && reportDate.nanoseconds !== undefined) {
        const milliseconds = reportDate.seconds * 1000 + reportDate.nanoseconds / 1000000;
        const dateObject = new Date(milliseconds); // Convert timestamp to Date object

        // Format the Date object into the desired string format
        return formatDateToDutchString(dateObject);
    }
  }

  // Helper function to get the minutes between two times
  const getMinutesBetweenTimes = (startTime, endTime) => {
    // Parse the time strings into Date objects
    let start = new Date(`1970-01-01T${startTime}:00`);
    let end = new Date(`1970-01-01T${endTime}:00`);

    // Calculate the difference in milliseconds
    let diff = end - start;

    // Convert milliseconds to minutes
    let minutes = diff / 1000 / 60 / 60;

    return minutes;
}

  // Helper function to get the simplified date
  const getSimplifiedDate = (date) => {
    const dateObject = new Date(date);
    const milliseconds = dateObject.getTime();

    return milliseconds;
  }

  // Get sheduled presence
  const getSheduledPresence = (date) => {

    // Get weekday of the date
    const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const weekday = dayNames[new Date(date).getDay()];

    // Get shedule for the date
    const sheduleForDate = schedule && schedule.filter(item => item.Day === weekday);

    if(sheduleForDate.length === 0) {
      return 0;
    }

    const getTotalMinutes = getMinutesBetweenTimes(sheduleForDate[0].StartTime, sheduleForDate[0].EndTime);

    return getTotalMinutes;

  }

  // Presence data
  const createPresenceData = () => {

    const presenceData = [];

    presenceReports && presenceReports.map(report => {

      // Calculate total hours of presence
      const startTime = report.Start;
      const endTime = report.End;

      // Calculate total hours of sheduled presence
      const totalHoursOfSheduledPresence = getSheduledPresence(report.Date);

      const totalHoursOfPresence = getMinutesBetweenTimes(startTime, endTime)

      // Create a simplified report date object
      const simplifiedDate = getSimplifiedDate(report.Date);

      presenceData.push({
        date: normalizeDate(report.Date),
        type: report.Type,
        absent: report.Absent,
        reason: report.Reason,
        Aanwezigheid: report.Type === 'absent' ? 0 : totalHoursOfPresence,
        Geroosterd: totalHoursOfSheduledPresence - (report.Type === 'absent' ? 0 : totalHoursOfPresence),
        present: report.Type === 'absent' ? 0 : totalHoursOfPresence,
        schedule: totalHoursOfSheduledPresence,
        id: report.ID,
        simplifiedDate: simplifiedDate,
        total: totalHoursOfPresence - totalHoursOfSheduledPresence
      });
    });

    // Order presence data by date
    const orderData = presenceData.sort((a, b) => a.simplifiedDate - b.simplifiedDate) 

    setPresenceData(orderData);
    setLoading(false); // Data has finished loading
  }

  useEffect(() => {
    if (presenceReports && presenceReports.length > 0) {
      createPresenceData();
    } else {
      setLoading(false); // Handle the case where there's no data
    }
  }, [presenceReports]);

  // Delete all reports
  const deleteAllReports = () => {
    presenceReports && presenceReports.map(report => {
      db.collection('PersonaPresenceReports').doc(report.docid).delete();
    });
  }

  // Add current date to timeline
  const currentDate = (startDate, endDate) => {
    const currentDate = new Date();
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);

    // Calculate total days
    const totalDays = (endDateObject - startDateObject) / (1000 * 60 * 60 * 24);

    // Calculate difference in days from start date
    const differenceInDaysFromStart = (currentDate - startDateObject) / (1000 * 60 * 60 * 24);

    // Calculate percentage difference in days from start date from total days
    const percentageDifference = (differenceInDaysFromStart / totalDays) * 100;

    return (
      <div id='timeline-current-date-container' style={{ width: `${percentageDifference}%` }}>
        <p>Vandaag</p>
        <div id='timeline-current-date'></div>
      </div>
    );
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {

      const tooltipType = (type) => {
        if (type === 'correct') {
          return 'Correct';
        } else if (type === 'absent') {
          return 'Afwezig';
        } else if (type === 'correction') {
          return 'Gecorrigeerd';
        }
      }

      return (
        <div className="custom-tooltip">
          <p className="label">{payload[0].payload.date ? payload[0].payload.date : 'Geen datum'}</p>
          <h2 className="label" id='presence-tooltip-type'>{tooltipType(payload[0].payload.type)}</h2>
          <p className="desc" style={{display: payload[0].payload.type === 'absent' ? 'none' : 'block' }}>Geroosterd: {payload[0].payload.schedule}</p>
          <p className="desc" style={{display: payload[0].payload.type === 'absent' ? 'none' : 'block' }}>Aanwezig: {payload[0].payload.present}</p>
          <p className="desc" style={{display: payload[0].payload.reason === '' ? 'none' : 'block' }}>Reden: {payload[0].payload.reason}</p>
          {/* <p className="desc"><b>Totaal: {payload[0].payload.total}</b></p> */}
        </div>
      );
    }
  
    return null;
  };

  return (
    <div style={{ display: personaNavigation === 'dashboard' ? 'block' : 'none' }}>
      <h2>Dashboard</h2>

      <div className='table-container dashboard-container'>
        <h2>Tijdlijn</h2>
        <div id='timeline-container'>
          <div>
            <h3>Startdatum</h3>
            <p>{item.StartDate}</p>
          </div>
          <div id='timeline-activities-container'>
            {currentDate(item.StartDate, item.EndDate)}
          </div>
          <div>
            <h3>Einddatum</h3>
            <p>{item.EndDate}</p>
          </div>
        </div>
      </div>

      <div className='table-container dashboard-container'>
        <h2>Doelen</h2>
        {goals && goals.map(goal => (
          <div key={goal.ID} id='persona-goal-item-container'>
            <h3>{goal.Goal}</h3>
            <PersonaGoalData goal={goal} />
          </div>
        ))}
      </div>

      <div className='table-container dashboard-container'>
        <h2>Aanwezigheid</h2>
        <div id='date-range-selecttor-container'>
          {/* <DateRangeOutlinedIcon /> */}
          <div className='select-date-container'>
            <p>Start</p>
            <input type="date" defaultValue={courseStartDate} onChange={(e) => setStartDate(e.target.value)}/>
          </div>
          <div className='select-date-container'>
            <p>Eind</p>
            <input type="date" defaultValue={courseEndDate} onChange={(e) => setEndDate(e.target.value)}/>
          </div>
        </div>
        <div id='presence-data-outer-container'>
          <div className='presence-data-container'>
            <img src={CalendarIcon} alt="erasor" id='register-presence-check-icon'/>
            <p><b>Totaal geroosterd</b></p>
            <p>{presenceReports.length}</p>
          </div>
          <div className='presence-data-container'>
            <img src={ErasorIcon} alt="erasor" id='register-presence-check-icon'/>
            <p>Waarvan gecorrigeert</p>
            <p>{totalCorrections}</p>
          </div>
          <div className='presence-data-container'>
            <img src={XIcon} alt="erasor" id='register-presence-check-icon'/>
            <p>Waarvan afwezig</p>
            <p>{totalAbsent}</p>
          </div>
        </div>
        <MixedBarChart data={presenceData} customTooltip={<CustomTooltip/>} />
        <div>
          <button onClick={createHistoricalPresenceReports}>Creeer historische rapporten</button>
        </div>
        <div>
          <button onClick={deleteAllReports}>Verwijder alle rapporten</button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;