import { useFirestoreGeneral } from '../../firebase/useFirestore'
import { db, timestamp } from '../../firebase/config'
import { client } from '../../hooks/Client'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useContext } from "react";
import Tooltip from '../../components/common/Tooltip'
import plusButton from '../../images/icons/plus-icon.png'
import useSettings from "../../hooks/Settings";
import deleteIcon from '../../images/icons/delete-icon.png'

const Presence = ({ personaNavigation, item }) => {
  // Context
  const [saved, setSaved] = useContext(SavedIcon)

  // Firestore
  const shedule = useFirestoreGeneral('PersonaShedule', 'PersonaID', item.ID ? item.ID : '')

  // Hooks
  const secundairyColor = useSettings().SecundairyColor

  // Add day
  const addDay = () => {
    db.collection('PersonaShedule')
      .add({
        Compagny: client,
        CompagnyID: client,
        Timestamp: timestamp,
        PersonaID: item.ID,
        PersonaDocid: item.docid ,
        Day: 'monday',
        StartTime: '',
        EndTime: ''
      })
  }

  // Delete day
  const deleteDay = (e) => {
    const docid = e.target.dataset.docid
    db.collection('PersonaShedule')
      .doc(docid)
      .delete()

    setSaved(true)
  }

  // Update weekday
  const updateWeekday = (e) => {
    const day = e.target.options[e.target.selectedIndex].value
    const docid = e.target.dataset.docid

    db.collection('PersonaShedule')
      .doc(docid)
      .update({
        Day: day
      })

      setSaved(true)
  }


  // Update start and endtime
  const startTimeHandler = (e) => {
    const value = e.target.value

    db.collection('PersonaShedule')
      .doc(e.target.dataset.docid)
      .update({
        StartTime: value
      })

    setSaved(true)
  }

  const endTimeHandler = (e) => {
    const value = e.target.value

    db.collection('PersonaShedule')
      .doc(e.target.dataset.docid)
      .update({
        EndTime: value
      })

    setSaved(true)
  }

  return (
    <div style={{ display: personaNavigation === 'presence' ? 'block' : 'none' }}>
      <h2>Aanwezigheid</h2>
      <div className='table-container dashboard-container'>
        <h2>Rooster</h2>
        {/* <button onClick={createShedule}>Klik</button> */}
        <div className='list-top-row-container'>
            <Tooltip content='Dag toevoegen' top='-60px'>
                <img src={plusButton} onClick={addDay} alt="" />
            </Tooltip>
        </div>
        <table>
          <tr>
              <th style={{backgroundColor: secundairyColor}}>DAG</th>
              <th style={{backgroundColor: secundairyColor}}>START</th>
              <th style={{backgroundColor: secundairyColor}}>EIND</th>
              <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
          </tr>
          {shedule && shedule.map(item => (
              <tr key={item.ID}>
                  <td>
                      <select name="" id=""  data-docid={item.docid}defaultValue={item.Day} onChange={updateWeekday}>
                          <option value="monday">Maandag</option>
                          <option value="tuesday">Dinsdag</option>
                          <option value="wednesday">Woensdag</option>
                          <option value="thursday">Donderdag</option>
                          <option value="friday">Vrijdag</option>
                          <option value="saturday">Zaterdag</option>
                          <option value="sunday">Zondag</option>
                      </select>
                  </td>
                  <td>
                    <input type="time" defaultValue={item.StartTime}  data-docid={item.docid} data-type='start' onChange={startTimeHandler} />
                  </td>
                  <td>
                    <input type="time" defaultValue={item.EndTime}  data-docid={item.docid} data-type='end' onChange={endTimeHandler} />
                  </td>
                  <td>
                      <img className='table-delete-icon' data-docid={item.docid} onClick={deleteDay} src={deleteIcon} alt="" />
                  </td>
              </tr>
        ))}
      </table>
      </div>
    </div>
  )
}

export default Presence