import informationIcon from '../../images/icons/information-icon.png'
import Tooltip from "../common/Tooltip"
import { useEffect, useState } from "react"
import { db } from "../../firebase/config"
import QuestionMeta from "../dashboard/QuestionMeta"
import { useFirestoreGeneral } from "../../firebase/useFirestore"
import OptionsIcon from '../../images/icons/options-icon-2.png'
import ChartType from "../common/ChartType"
import Location from '../../hooks/Location'
import BarChartIcon from '../../images/icons/analytics-icon.png'
import PieChartIcon from '../../images/icons/pie-chart-icon-2.png'

const ResearchData = ({fieldType, fieldId, researchId, startDate, endDate, docid, liveReportHidden, graphType}) => {
  // State
  const [title, setTitle] = useState('')
  const [numberOfMeasureMoments, setNumberOfMeasureMoments] = useState('')
  const [type, setType] = useState('')
  const [momentId, setMomentId] = useState('')
  const [showOptions, setShowOptions] = useState(false)

    // Hooks
    const currentPage = Location()[2]

  // Firestore 
  const measureMoments = useFirestoreGeneral('MeasureMoments', 'ResearchID', researchId ? researchId : '')

  // Function to format date
  function formatDate(date) {
      const options = { day: 'numeric', month: 'short', year: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    }

  // Get number of measure moments 
  useEffect(() => {
      if(measureMoments){
          setNumberOfMeasureMoments(measureMoments.length)
      }
  }, [measureMoments])

  // Get type of moment
  useEffect(() => {
      if(numberOfMeasureMoments === 1){
          setType(`${fieldType}-moment`)
          setMomentId(measureMoments[0].ID)
      } else if(numberOfMeasureMoments > 1){
          setType(`${fieldType}-development`)
      } else {
          setType('output')
      }
  }, [numberOfMeasureMoments])

   // Function to update indicator visibility
   const updateIndicatorVisibility = async (e) => {

        const type = e.target.dataset.type 

        await db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            LiveReportHidden: type === 'on' ? false : true
        })
    }

    // Function to select graph type
    const selectGraphType = async (e) => {
        const type = e.target.dataset.type

        await db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            GraphType: type 
        })
    }

    // Function to set tooltip content
  const tooltipContent = () => {


      const typeSetting = () => {
          if(measureMoments.length === 1){
              return 'Meetmoment'
          } else if(measureMoments.length > 1){
              return 'Ontwikkeling'
          } else{
              return 'Outputresultaat'
          }
      }
      
      // Function to get research title
      const researchTitle = async () => {

        //   const snapshot = await db.collection('Research')
        //   .where('ID', '==', researchId ? researchId : "")
        //   .get()
          
        //   let title = ''
        //   let id = ''
      
        //   snapshot.docs.map(doc => {
        //       title = doc.data().Title
        //       id = doc.ID
        //   })
      
        //   setTitle(title)
        }
      
        // researchTitle()
      
  return(
      <div className="home-kpi-tooltip-container">
          <p><b>Onderzoek</b></p>
          <p>{title}</p>
          <p><b>Type</b></p>
          <p>{typeSetting()}</p>
          <p><b>Periode</b></p>
          <p>{startDate && formatDate(startDate)} - {endDate && formatDate(endDate)}</p>
      </div>
      )
  }

  return (
      <div className="home-kpi-chart-container">
          <div className="home-kpi-description-container">
              <QuestionMeta field={fieldId}/>
              <Tooltip content={tooltipContent()} width={'50px'} top='30px' left='-100px'>
                  <img src={informationIcon} alt=""/>
              </Tooltip>
              <div id='show-options-container' style={{display: currentPage === 'livereportbuilder' ? 'block' : 'none'}}>
                    <Tooltip content={'Opties'} width={'50px'} top='30px'>
                        <img id='options-icon' src={OptionsIcon} alt="" onClick={() => setShowOptions(!showOptions)}/>
                    </Tooltip>
                    {showOptions &&
                        <div className="home-kpi-options-container">
                            <div className="home-kpi-option-container">
                                <p><b>Zichtbaarheid</b></p>
                                <div className='hom-kpi-option-select-container'>
                                    <p 
                                    className="home-kpi-indicator-visibility-option" 
                                    style={{color: liveReportHidden ? 'black' : 'green'}} 
                                    data-type={'on'} 
                                    onClick={updateIndicatorVisibility}>
                                        Aan
                                    </p>
                                    <p>/</p>
                                    <p 
                                    className="home-kpi-indicator-visibility-option" 
                                    style={{color: liveReportHidden ? 'red' : 'black'}} 
                                    data-type={'off'} 
                                    onClick={updateIndicatorVisibility}>
                                        Uit
                                    </p>
                                </div>
                            </div>
                            <div className="home-kpi-option-container">
                                <p><b>Visualisatie</b></p>
                                <div className='hom-kpi-option-select-container'>
                                    <img src={BarChartIcon} data-type={'bar'} onClick={selectGraphType} style={{border: graphType === 'bar' ? '1px solid green' : 'none', padding: '3px'}}/>
                                    <img src={PieChartIcon} data-type={'pie'} onClick={selectGraphType} style={{border: graphType === 'pie' ? '1px solid green' : 'none', padding: '3px'}}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
          </div>
          <ChartType type={type} momentId={momentId} fieldId={fieldId} startDate={startDate} endDate={endDate} researchId={researchId} graphType={graphType}/>
      </div>
  )
}

export default ResearchData