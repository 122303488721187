import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';

const ParagraphBarChartResponsive = ({data}) => {
   
  const getPath = (x, y, width, height) => {
    return `M${x},${y}
    L${x + width},${y}
    L${x + width},${y + height}
    L${x},${y + height}
    Z`;
  };
  
  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;
  
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  return (
    <ResponsiveContainer width="90%" height={300}>
        <BarChart
        data={data && data[0]}
        margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="value" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
            {data ? data[0].map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
            ))
            : null}
        </Bar>
        </BarChart>
    </ResponsiveContainer>
  )
}

export default ParagraphBarChartResponsive